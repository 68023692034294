<template>
  <div id="orderPay" class="fixedMode">
    <div class="mainScroll">
      <div class="topInfoWrap">
        <p class="paytxt">支付金额</p>
        <p class="goodsPrize" v-if="!!ordernfo">
          ¥
          <span  class="integer">{{getPricr(ordernfo.actualAmount || ordernfo.productAmount)}}</span>
          <!-- <span class="integer">{{
            priceDecimalNum(ordernfo.actualAmount)
          }}</span
          ><span class="decimal">{{ priceIntNum(ordernfo.actualAmount) }}</span> -->
        </p>
        <p class="goodsName">{{spuInfo.pvName || ordernfo.productName }}</p>
      </div>
      <div class="partBg">
        <!-- <div class="partBgWrap" v-if="walletpay||hfpay">
          <div class="payMethods">
            <img src="@/assets/icons/yidong.png" alt class="payImg" />
            移动支付
          </div>
          <div class="subPayList">
            <div class="subPayMethods" v-if="walletpay">
              <input
                type="radio"
                v-model="payType"
                value="22"
                class="radioInput"
                name="payWays"
                id="qianbao"
              />
              <label class="subPayBar radioLabel" for="qianbao">
                <p class="payName">移动钱包支付</p>
                <p class="payDesc highLight">首单减免10元</p>
              </label>
            </div>
            <div class="subPayMethods" v-if="hfpay">
              <input
                type="radio"
                v-model="payType"
                value="19"
                class="radioInput"
                name="payWays"
                id="huafei"
              />
              <label class="subPayBar radioLabel" for="huafei">
                <p class="payName">移动话费支付</p>
                <p class="payDesc">单单有优惠</p>
              </label>
            </div>
          </div>
        </div> -->
        <!-- <div class="partBgWrap" v-if="wxpay">
          <input
            type="radio"
            v-model="payType"
            value="5"
            class="radioInput"
            name="payWays"
            id="wxPay"
          />
          <label class="payMethods radioLabel" for="wxPay">
            <img src="@/assets/icons/wx.png" alt class="payImg" />
            微信支付
          </label>
        </div>
        <div class="partBgWrap" v-if="alipay">
          <input
            type="radio"
            v-model="payType"
            value="12"
            class="radioInput"
            name="payWays"
            id="aliPay"
          />
          <label class="payMethods radioLabel" for="aliPay">
            <img src="@/assets/icons/zfb.png" alt class="payImg" />
            支付宝支付
          </label>
        </div> -->
        <!-- <div class="partBgWrap">
          <input
            type="radio"
            v-model="payType"
            value=""
            class="radioInput"
            name="payWays"
            id="unionPay"
          />
          <label class="payMethods radioLabel" for="unionPay">
            <img src="@/assets/icons/yinlian.png" alt class="payImg" />
            银联支付
          </label>
        </div> -->
        <div class="partBgWrap" v-if="wxpay">
          <input
            type="radio"
            v-model="payType"
            value="0"
            class="radioInput"
            name="payWays"
            id="wxPay"
          />
          <label class="payMethods radioLabel" for="wxPay">
            <img src="@/assets/icons/wx.png" alt class="payImg" />
            微信支付
          </label>
        </div>
        <div class="partBgWrap" v-if="alipay">
          <input
            type="radio"
            v-model="payType"
            value="12"
            class="radioInput"
            name="payWays"
            id="aliPay"
          />
          <label class="payMethods radioLabel" for="aliPay">
            <img src="@/assets/icons/zfb.png" alt class="payImg" />
            支付宝支付
          </label>
        </div>
         <div class="partBgWrap">
          <input
            type="radio"
            v-model="payType"
            value="22"
            class="radioInput"
            name="payWays"
            id="wallatPay"
          />
          <label class="payMethods radioLabel" for="wallatPay">
            <img src="@/assets/icons/wallet.png" alt class="payImg" />
            移动钱包支付
          </label>
        </div>
      </div>
    </div>
    <div class="bottomBtn">
      <button type="button" class="buybtn" @click="toPay">确认支付</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderPay",
  data() {
    return {
      spuId: this.$route.query.spuId,
      sceneNo: this.$route.query.sceneNo,
      bizId: this.$route.query.bizId,
      productId:this.$route.query.productId,
      from: this.$route.query.from,
      skuId: this.$route.query.skuId,
      spuInfo: {
      },
      ordernfo:{
        
      },
      payType: null, //  支付类型
      isNeedLeaveComfirm: true, //  离开页面问询
      submiting: false,
      alipay:true,         //支付宝
      hfpay:true,          //话费
      walletpay:true,      //掌厅钱包
      wxpay:true          //微信支付
    };
  },
  components: {},
  mounted() {
    // 从确定订单页面跳转而来会有缓存 【防止此页面被分享
    // let baseInfo = sessionStorage.getItem(`spuBuy${this.spuId}`);
    let ordernfo = sessionStorage.getItem(`${this.bizId}Order`);
    let spuInfo = sessionStorage.getItem(`spuBuy${this.productId}`)
    console.log(ordernfo)
    // if (!ordernfo) {
    //   //  如果没有缓存 跳回详情页
    //     this.$router.replace({
    //     path: "/h/scm/virtual/goodsDetail",
    //     query: {
    //      sceneNo:this.$route.sceneNo
    //     }
    //   });
    //   return;
    // }
  
    if(sessionStorage.getItem('orderBizId')){
      sessionStorage.removeItem("orderBizId");  
    }
    this.ordernfo = JSON.parse(ordernfo);
    this.spuInfo = JSON.parse(spuInfo).spuInfo;
    // this.hasPaid();
  },
  created(){
    this.judgePayWays();
  },
  methods: {
    judgePayWays(){
      this.request.get('/api/scm/product/spu/pay/method',{spuId:this.$route.query.productId}).then((res) =>{
          const {alipay,hfpay,walletpay,wxpay} = res;
          this.alipay = alipay;
          this.hfpay = hfpay;
          this.walletpay = walletpay;
          this.wxpay = wxpay;
      
      })
    },
    getPricr(num){
      return num/100
    },
    //  截取价格小数
    priceIntNum(num) {
      return "." + this.global.getNumLastDoubleDigit(num);
    },
    //  截取整数
    priceDecimalNum(num) {
      let tmp = String(num);
      if (tmp.length < 3) return "0";
      return tmp.substr(0, tmp.length - 2);
    },
    judgePayElement(){                 //判断是否微信环境
      var ua = window.navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i) == 'micromessenger') {
          return true;
      } else {
          return false;
      }
    },
    wxPayWays(){
        var url = '/api/scm/wechatPay/getAuthUrl?redirectUri='+"/h/scm/wxVirtual";
        this.request.post(url,{redirectUri:'/h/scm/wxVirtual'}).then(res =>{
          if(res){
            sessionStorage.setItem('orderBizId',this.bizId)
            sessionStorage.setItem('sceneNo',this.sceneNo)
            window.location.href = res;
          }
        })
    },
    //  去支付
    toPay() {
      if (this.submiting) return;
      if (!this.payType) {
        this.$toast({
          message: "请选择支付方式",
          position: "bottom"
        });
        return;
      }
      this.submiting = true;
      var payType;
     
      if(this.payType=='0'){//微信支付
        // payType = this.judgePayElement()?7:5
        var isWxElement = this.judgePayElement();//判断是否微信环境,true为微信环境内
        if(isWxElement){           //微信环境内
          this.wxPayWays();
          return;
        }else{
          payType = 7;
        }
       
      }else{
        payType = this.payType;
      }
      // let url = `${window.location.origin}/h/scm/order/payCheck?bizId=${this.bizId}&payType=${this.payType}&skuId=${this.skuId}`,
      var  payBizId = this.bizId + String(new Date().getTime()).substr(-4, 4);
      var  url = `${window.location.origin}/h/scm/order/payCheck?bizId=${this.bizId}&payType=${payType}&sceneNo=${this.sceneNo}&virtual=1`,payBizId = payBizId;
      this.request
        .postFrom(
          `/api/scm/pay/order?bizId=${this.bizId}&virtual=1&payType=${payType}&payBizId=${payBizId}&returnUrl=${encodeURIComponent(url)}`,
          {}
        )
        .then((res) => {
          if (!!res && !!res.paymentToken) {
            this.isNeedLeaveComfirm = false;
            if (payType == 5 ||payType == 7 || payType == 12 || payType == 22) {
              sessionStorage.setItem(`payed${this.bizId}`, true);
              this.submiting = false;
              //  支付宝和微信跳转链接
              window.location.href = res.paymentToken;
            } else {
              this.$router.replace({
                path: "/h/scm/order/payCheck",
                query: {
                  bizId: this.bizId,
                  payType: payType,
                  productId: this.$route.query.productId,
                  virtual:true,
                  sceneNo:this.sceneNo
                }
              });
            }
          } else {
            this.submiting = false;
            if (!!res)
              this.$toast({
                message: "支付失败，请稍候重试",
                position: "bottom"
              });
          }
        });
    },
    //  判断是否已经付钱
    hasPaid() {
      if (!sessionStorage.getItem(`payed${this.bizId}`)) return;
      this.$dialog
        .confirm({
          message: "请确认是否已经完成支付？",
          cancelButtonText: "重新支付",
          confirmButtonText: "已经支付",
          closeOnPopstate: false
        })
        .then(() => {
          this.checkOrder();
        })
        .catch(() => {
          this.submiting = false;
        });
    },
    //  检查订单
    checkOrder() {
      this.submiting = true;
      this.request
        .get("/api/scm/pay/query", {
          bizId: this.bizId
        })
        .then((res) => {
          let type = res && res.status,
            tips = !type
              ? "订单处理中，若您确认已经支付，请稍候刷新订单详情页面同步订单状态"
              : type == 2
              ? "该订单已被取消"
              : "交易成功";
          this.$toast({
            message: tips,
            position: "bottom",
            duration: !type ? 5000 : 3000
          });
          this.isNeedLeaveComfirm = false;
          this.$router.replace({
             path: "/h/scm/order/orderList",
             query: {
          from: "hotSale",
          sceneNo:this.$route.query.sceneNo
        }
          });
          this.loading = false;
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    if (this.isNeedLeaveComfirm) {
      this.$dialog
        .confirm({
          message: "确认离开付款页面吗？\n未付款的订单可在订单列表查看。",
          cancelButtonText: "确认离开",
          confirmButtonText: "继续付款",
          closeOnPopstate: false
        })
        .then(() => {
          // on confirm
          next(false);
        })
        .catch(() => {
          // on cancel
          next();
        });
    } else {
      next();
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem(`spuBuy${this.productId}`);
    sessionStorage.removeItem(`${this.bizId}Order`);
    sessionStorage.removeItem(`payed${this.bizId}`);
  }
};
</script>

<style lang="scss" scoped>
.fixedMode {
  background: #efefef;
}
.radioLabel {
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    /* prettier-ignore */
    border: 1PX solid #999;
  }
}
.radioInput:checked + .radioLabel::after {
  background: #ff583d url(~@/assets/icons/gou.png) center / 100% 100% no-repeat;
  border-color: #ff583d;
}
.topInfoWrap {
  height: 270px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  .paytxt {
    color: #666;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .goodsPrize {
    line-height: 60px;
    height: 60px;
    font-size: 30px;
    margin-bottom: 20px;
    color: #ea3d39;
    .integer {
      font-size: 60px;
      font-weight: bold;
    }
    .decimal {
      font-size: 36px;
      font-weight: 600;
    }
  }
  .goodsName {
    color: #333;
    font-size: 26px;
    line-height: 1.5;
    font-weight: bold;
  }
}
.partBg {
  background: #fff;
  border-radius: 20px;
  margin: 30px;
}
.partBgWrap {
  padding: 40px 30px 0 40px;
  min-height: 90px;
  position: relative;
  .payMethods {
    height: 52px;
    display: flex;
    font-size: 34px;
    align-items: center;
    .payImg {
      display: block;
      height: 52px;
      margin-right: 30px;
    }
    &.radioLabel::after {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  & + .partBgWrap {
    border-top: 1px solid #dadbdd;
  }
}
.subPayList {
  padding-bottom: 30px;
  .subPayMethods {
    margin-top: 20px;
    padding-left: 80px;
    .subPayBar {
      height: 80px;
      display: block;
      .payName {
        line-height: 42px;
        color: #333;
        font-size: 30px;
      }
      .payDesc {
        color: #888;
        font-size: 24px;
        line-height: 32px;
        &.highLight {
          color: #fa5220;
        }
      }
      &.radioLabel::after {
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
  }
}

.bottomBtn {
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  .buybtn {
    width: 690px;
    height: 96px;
    display: block;
    border-radius: 96px;
    background: linear-gradient(to right, #f62300, #fa5421);
    color: #fff;
    font-size: 36px;
  }
}
</style>